import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import Components from './routes.json'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/actualizacion',
    name: 'Actualizacion',
    component: () => import('../components/FormList.vue'),
    props: {
      component : Components.Actualizacion
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/aliados',
    name: 'Aliados',
    component: () => import('../components/ImageList.vue'),
    props: {
      component : Components.Aliados
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/contenido_reproducible',
    name: 'Contenido Reproducible',
    component: () => import('../components/FullFormList.vue'),
    props: {
      component : Components.Contenido_Reproducible,
      cFunction : 'Lista'
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/contenido_reproducible/editar/:id',
    name: 'Contenido Reproducible - Editar',
    component: () => import('../components/FullForm.vue'),
    props: {
      component : Components.Contenido_Reproducible,
      cFunction : 'Editar'
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/contenido_reproducible/crear',
    name: 'Contenido Reproducible - Crear',
    component: () => import('../components/FullForm.vue'),
    props: {
      component : Components.Contenido_Reproducible,
      cFunction : 'Crear'
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/devocionales',
    name: 'Devocionales',
    component: () => import('../components/FullFormList.vue'),
    props: {
      component : Components.Devocionales,
      cFunction : 'Lista'
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/Devocionales/editar/:id',
    name: 'Devocionales - Editar',
    component: () => import('../components/FullForm.vue'),
    props: {
      component : Components.Devocionales,
      cFunction : 'Editar'
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/Devocionales/crear',
    name: 'Devocionales - Crear',
    component: () => import('../components/FullForm.vue'),
    props: {
      component : Components.Devocionales,
      cFunction : 'Crear'
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/galeria',
    name: 'Galeria',
    component: () => import('../components/ImageList.vue'),
    props: {
      component : Components.Galeria
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/info',
    name: 'Info',
    component: () => import('../components/InfoComponent.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/noticias',
    name: 'Noticias',
    component: () => import('../components/FullFormList.vue'),
    props: {
      component : Components.Noticias,
      cFunction : 'Lista'
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/Noticias/editar/:id',
    name: 'Noticias - Editar',
    component: () => import('../components/FullForm.vue'),
    props: {
      component : Components.Noticias,
      cFunction : 'Editar'
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/Noticias/crear',
    name: 'Noticias - Crear',
    component: () => import('../components/FullForm.vue'),
    props: {
      component : Components.Noticias,
      cFunction : 'Crear'
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/novedades',
    name: 'Novedades',
    component: () => import('../components/FullFormList.vue'),
    props: {
      component : Components.Novedades,
      cFunction : 'Lista'
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/Novedades/editar/:id',
    name: 'Novedades - Editar',
    component: () => import('../components/FullForm.vue'),
    props: {
      component : Components.Novedades,
      cFunction : 'Editar'
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/Novedades/crear',
    name: 'Novedades - Crear',
    component: () => import('../components/FullForm.vue'),
    props: {
      component : Components.Novedades,
      cFunction : 'Crear'
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/popup',
    name: 'Popup',
    component: () => import('../components/FullForm.vue'),
    props: {
      component : Components.Popup,
      cFunction : 'Editar'
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/producciones',
    name: 'Producciones',
    component: () => import('../components/FullFormList.vue'),
    props: {
      component : Components.Producciones,
      cFunction : 'Lista'
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/Producciones/editar/:id',
    name: 'Producciones - Editar',
    component: () => import('../components/FullForm.vue'),
    props: {
      component : Components.Producciones,
      cFunction : 'Editar'
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/Producciones/crear',
    name: 'Producciones - Crear',
    component: () => import('../components/FullForm.vue'),
    props: {
      component : Components.Producciones,
      cFunction : 'Crear'
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/Producciones/Playlist/:produccion',
    name: 'Playlist',
    component: () => import('../components/PlayList.vue'),
    props: {
      component : Components.Playlist,
      cFunction : 'Lista'
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/Producciones/Playlist/:produccion/editar/:id',
    name: 'Playlist Editar',
    component: () => import('../components/FullForm.vue'),
    props: {
      component : Components.Playlist,
      cFunction : 'Editar'
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/Producciones/Playlist/:produccion/crear',
    name: 'Playlist Crear',
    component: () => import('../components/FullForm.vue'),
    props: {
      component : Components.Playlist,
      cFunction : 'Crear'
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/programas',
    name: 'Programas',
    component: () => import('../components/FullFormList.vue'),
    props: {
      component : Components.Programas,
      cFunction : 'Lista'
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/Programas/editar/:id',
    name: 'Programas - Editar',
    component: () => import('../components/FullForm.vue'),
    props: {
      component : Components.Programas,
      cFunction : 'Editar'
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/Programas/crear',
    name: 'Programas - Crear',
    component: () => import('../components/FullForm.vue'),
    props: {
      component : Components.Programas,
      cFunction : 'Crear'
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/recomendaciones',
    name: 'Recomendaciones',
    component: () => import('../components/FullFormList.vue'),
    props: {
      component : Components.Recomendaciones,
      cFunction : 'Lista'
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/Recomendaciones/editar/:id',
    name: 'Recomendaciones - Editar',
    component: () => import('../components/FullForm.vue'),
    props: {
      component : Components.Recomendaciones,
      cFunction : 'Editar'
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/Recomendaciones/crear',
    name: 'Recomendaciones - Crear',
    component: () => import('../components/FullForm.vue'),
    props: {
      component : Components.Recomendaciones,
      cFunction : 'Crear'
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/redes_sociales',
    name: 'Redes Sociales',
    component: () => import('../components/FormList.vue'),
    props: {
      component : Components.Redes_Sociales
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/tarjeta',
    name: 'Tarjeta',
    component: () => import('../components/FormList.vue'),
    props: {
      component : Components.Tarjeta
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/versiculos',
    name: 'Versiculos',
    component: () => import('../components/FullFormList.vue'),
    props: {
      component : Components.Versiculos
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/Versiculos/editar/:id',
    name: 'Versiculos - Editar',
    component: () => import('../components/FullForm.vue'),
    props: {
      component : Components.Versiculos,
      cFunction : 'Editar'
    },
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/Versiculos/crear',
    name: 'Versiculos - Crear',
    component: () => import('../components/FullForm.vue'),
    props: {
      component : Components.Versiculos,
      cFunction : 'Crear'
    },
    meta: {
      requiresAuth: true,
    }
  },
]

const router = createRouter({
  mode: 'hash',
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const removeListener = onAuthStateChanged(
      getAuth(),
      (user)=>{
        removeListener();
        resolve(user);
      },
      reject
    )
  })
}

router.beforeEach( async (to, from, next) => {
  if(to.matched.some((record) => record.meta.requiresAuth)){
    if(await getCurrentUser()){
      next();
    }else{
      next('/');
    }
  }else{
    next();
  }
}
);

export default router
