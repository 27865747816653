<template>
  <nav v-bind:style="{ right: displayMenu ? 'calc(100% - 40px)' : '0px' }">
    <div class="menu_button">
      <i class="fa-solid fa-bars" :onClick="toggleMenu" ></i>
    </div>
    <h1>Menu</h1>
    <div class="menu_container">
      <MenuComponent v-if="isLoggedIn" @menuItemClicked="hideMenu"/>
    </div>
    <ButtonComponent @click="logout" v-if="isLoggedIn" buttonColor="#751919" buttonText="Cerrar Sesion" buttonTextColor="white"/>
  </nav>
  <main v-bind:style="{ flex: isLoggedIn ? '4' : '3' }">
    <router-view/>
  </main>
  <aside v-if="!isLoggedIn">
    <LoginView/>
  </aside>
</template>

<script>
import { onAuthStateChanged, getAuth, signOut } from 'firebase/auth';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'
import ButtonComponent from './components/ButtonComponent.vue';
import LoginView from './views/LoginView.vue';
import MenuComponent from './components/MenuComponent.vue'
import axios from 'axios';

export default {
  setup() {
    const router = useRouter();
    const store = useStore();
    const isLoggedIn = ref(false);
    let auth;
    onMounted(() => {
      document.title='Plataforma - La Voz Live'
      auth = getAuth();
      onAuthStateChanged(auth, (user) => {
        if (user) {
          store.commit('setOperador', user)        
          isLoggedIn.value = true;
          let url = store.state.backend + '/access';
          axios.get(url).then(res => {
              if(res.status == 200){
                  let uid = user.uid
                  store.commit('setMenuAccess', res.data[uid])
              }
          })
        } else {
          isLoggedIn.value = false;
        }
      });

    });

    const logout = () => {
      signOut(auth).then(() => {
        router.push('/');
        router.go(0)
      });
    };

    return {
      isLoggedIn,
      logout,
    };
  },
  components: {
    ButtonComponent,
    LoginView,
    MenuComponent
  },
  data(){
    return{
      displayMenu:true
    }
  },
  methods:{
    toggleMenu(){
      this.displayMenu= !this.displayMenu
    },
    hideMenu() {
      this.displayMenu = true;
    }
  }
};
</script>


<style>
html{
  display: flex;
  align-items: center;
  justify-content: center;
  --blue-50: #f0f9ff;
  --blue-100: #e0f2fe;
  --blue-200: #bae5fd;
  --blue-300: #7cd2fd;
  --blue-400: #37bbf9;
  --blue-500: #0da3ea;
  --blue-600: #0182c8;
  --blue-700: #0267a2;
  --blue-800: #065886;
  --blue-900: #0c4c73;
  --blue-950: #082e49;
}
body{
  background-color: var(--blue-50);
  margin: 0;
  width: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--blue-950);
  display: flex;
  align-items: start;
  justify-content: flex-start;
  flex-direction:row;
  width: 100%;
  box-sizing: border-box;
  height: 100vh;
  overflow-y: scroll;
}
main{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 30px;
  box-sizing:border-box;
}
nav {
  background-color: var(--blue-950);
  color: var(--blue-50);
  padding: 30px;
  flex: 1;
  display: flex;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  gap: 15px;
  min-height: 100vh;
  height: 100%;
  top: 0;
  position: sticky;
  align-items: center;
}
nav h1{
  font-weight:900;
}
nav a{
  color: inherit;
  text-decoration: none;
  font-weight: bold;
  text-align: left;
  font-size: large;
}
nav a:hover{
  color: var(--blue-300)
}
nav a.router-link-exact-active {
  color: var(--blue-500);
}
.menu_container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: max-content;
  max-width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  gap: 15px;
}
aside{
  flex: 1;
  padding: 10px;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  position: sticky;
  box-sizing: border-box;
}
main{
  display: flex;
  align-items: center;
  justify-content: center;
}
button{
  cursor: pointer;
}
button:hover{
  filter: brightness(1.2);
}
a:hover{
  filter: brightness(1.2);
}
h1,h2,h3,h4{
  margin:0;
}
.menu_button{
  display: none;
  color: var(--blue-50);
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: x-large;
}
@media (max-width:800px) {
  #app{
    padding-left: 40px;
    flex-direction: column;
    overflow-y: scroll;
    width: 100%;
  }
  nav{
    display:flex;
    position: absolute;
    z-index: 10;
    top:0;
    transition:all 1s;
  }
  .menu_button{
    display: block;
  }
  aside{
    position: relative;
    z-index: 0;
  }
}
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: var(--blue-900);
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
