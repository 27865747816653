<template>
  <div class="home">
    <img class="home_logo" src='../assets/la-voz-100.3-b.png' alt="La Voz Live">
    <h1>Plataforma</h1>
    <h3>{{ this.user.email }}</h3>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
  methods:{
  },
  data(){
    return{
      user: {
        email: '',
        uid: '',
        token: ''
      }
    }
  },
  mounted(){
    this.user.email = this.$store.state.operador.email
  },
  updated(){
    setTimeout(()=>{
      this.user.email = this.$store.state.operador.email
    }, 1000)
  }
}
</script>

<style scoped>
  .home{
    background-color: var(--blue-50);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px;
    min-height: 100vh;
    box-sizing: border-box;
    border-radius: 10px;
    gap: 15px;
  }
  .home_logo{
    width: 30vw;
    margin: 20px;
  }
  button{
    font-weight: 600;
  }
  @media screen and (max-width:800px) {
    .home{
      min-height: fit-content;
    }
  }
</style>