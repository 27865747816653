<template>
<router-link to="/" v-if="access['Home']" @click="handleItemClick()">Home</router-link>
<router-link to="/Actualizacion" v-if="access['Actualizacion']" @click="handleItemClick()">Actualizacion</router-link>
<router-link to="/Aliados" v-if="access['Aliados']" @click="handleItemClick()">Aliados</router-link>
<router-link to="/Contenido_Reproducible" v-if="access['Contenido Reproducible']" @click="handleItemClick()">Contenido Reproducible</router-link>
<router-link to="/Devocionales" v-if="access['Devocionales']" @click="handleItemClick()">Devocionales</router-link>
<router-link to="/Galeria" v-if="access['Galeria']" @click="handleItemClick()">Galeria</router-link>
<router-link to="/Info" v-if="access['Info']" @click="handleItemClick()">Info</router-link>
<router-link to="/Noticias" v-if="access['Noticias']" @click="handleItemClick()">Noticias</router-link>
<router-link to="/Novedades" v-if="access['Novedades']" @click="handleItemClick()">Novedades</router-link>
<router-link to="/Popup" v-if="access['Popup']" @click="handleItemClick()">Popup</router-link>
<router-link to="/Producciones" v-if="access['Producciones']" @click="handleItemClick()">Producciones</router-link>
<router-link to="/Programas" v-if="access['Programas']" @click="handleItemClick()">Programas</router-link>
<router-link to="/Recomendaciones" v-if="access['Recomendaciones']" @click="handleItemClick()">Recomendaciones</router-link>
<router-link to="/Redes_Sociales" v-if="access['Redes Sociales']" @click="handleItemClick()">Redes Sociales</router-link>
<router-link to="/Tarjeta" v-if="access['Tarjeta']" @click="handleItemClick()">Tarjeta</router-link>
<router-link to="/Noticias/editar/12" v-if="access['EVicencio']" @click="handleItemClick()">De Todo Un Poco</router-link>
<router-link to="/Noticias/editar/13" v-if="access['GHernandez']" @click="handleItemClick()">Guillermo Hernandez</router-link>
<router-link to="/Versiculos" v-if="access['Versiculos']" @click="handleItemClick()">Versiculos</router-link>
</template>
<script>
export default {
    name: 'MenuComponent',
    created(){
        this.fetchMenuData();
    },
    mounted(){
        setTimeout(()=>{
            this.fetchMenuData();
        },1000)
    },
    methods:{
        handleItemClick() {
            this.$emit('menuItemClicked');
        },
        fetchMenuData(){
            const access={
                "Home":true,
                "Actualizacion":this.$store.state.menuAccess.Actualizacion,
                "Aliados":this.$store.state.menuAccess.Aliados,
                "Contenido Reproducible":this.$store.state.menuAccess['Contenido Reproducible'],
                "Devocionales":this.$store.state.menuAccess.Devocionales,
                "Galeria":this.$store.state.menuAccess.Galeria,
                "Info":this.$store.state.menuAccess.Info,
                "Noticias":this.$store.state.menuAccess.Noticias,
                "Notificaciones":this.$store.state.menuAccess.Notificaciones,
                "Novedades":this.$store.state.menuAccess.Novedades,
                "Popup":this.$store.state.menuAccess.Popup,
                "Producciones":this.$store.state.menuAccess.Producciones,
                "Programas":this.$store.state.menuAccess.Programas,
                "Recomendaciones":this.$store.state.menuAccess.Recomendaciones,
                "Redes Sociales":this.$store.state.menuAccess['Redes Sociales'],
                "Tarjeta":this.$store.state.menuAccess.Tarjeta,
                "Versiculos":this.$store.state.menuAccess.Versiculos,
                "EVicencio":this.$store.state.menuAccess.EVicencio
            }
            this.access=access
        }
    },
    data(){
        return{
            access:{
                "Home":true,
                "Actualizacion":false,
                "Aliados":false,
                "Contenido Reproducible":false,
                "Devocionales":false,
                "Galeria":false,
                "Info":false,
                "Noticias":false,
                "Notificaciones":false,
                "Novedades":false,
                "Popup":false,
                "Producciones":false,
                "Programas":false,
                "Recomendaciones":false,
                "Redes Sociales":false,
                "Tarjeta":false,
                "Versiculos":false,
                "EVicencio":false
            }
        }
    },
}
</script>