import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store';
import {initializeApp} from 'firebase/app'

const firebaseConfig = {
    apiKey:process.env.VUE_APP_APIKEY,
    authDomain:process.env.VUE_APP_AUTHDOMAIN,
    databaseURL:process.env.VUE_APP_DATABASEURL,
    projectId:process.env.VUE_APP_PROJECTID,
    storageBucket:process.env.VUE_APP_STORAGEBUCKET,
    messagingSenderId:process.env.VUE_APP_MESSAGINGSENDERID,
    appId:process.env.VUE_APP_APPID,
    measurementId:process.env.VUE_APP_MEASUREMENTID
}

initializeApp(firebaseConfig);

const app = createApp(App);
app.use(store).use(router).mount('#app');
