import { createStore } from 'vuex';

const store = createStore({
  state: {
    isActive: false,
    backend: 'http://api.lavozfmlive.com',
    menuAccess: {},
    operador : {}
  },
  mutations: {
    setIsActive(state, newData) {
      state.isActive = newData;
    },
    setMenuAccess(state, newData){
      state.menuAccess = newData;
    },
    setOperador(state, newData){
      state.operador = newData
    }
  },
  actions: {
    setIsActive({ commit }) {
      commit('setIsActive');
    },
    setMenuAccess({commit}){
      commit('setMenuAccess');
    },
    setOperador({commit}){
      commit('setOperador')
    }
  },
  getters: {
    isGlobalVariableActive: (state) => state.isActive,
  },
});

export default store;
