<template>
    <button :style="{background: buttonColor, color: buttonTextColor, borderColor: buttonTextColor}" class="btn">
        {{ buttonText }}
    </button>
</template>

<script>
export default{
    name: "ButtonComponent",
    props: {
        buttonColor: String,
        buttonText: String,
        buttonTextColor: String
    }
}
</script>

<style scoped>
    .btn{
        border: solid 1px;
        padding: 10px 20px;
        border-radius: 5px;
    }
</style>