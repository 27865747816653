<template>
    <div class="login_container">
        <h1>Iniciar Sesion</h1>
        <p v-if="errorMessage">{{ errorMessage }}</p>
        <p>Correo <br><input type="text" placeholder="Email" v-model="email" class="text"></p>
        <p>Contraseña <br><input type="password" placeholder="Contraseña" v-model="password" class="password"></p>
        <p><button type="submit" @click="login">Iniciar Sesion</button></p>
    </div>
</template>

<script setup>
/* eslint-disable */
import {ref} from 'vue'
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth'
import { useRouter } from 'vue-router';
const router = useRouter();
const email = ref("");
const password = ref("");
const errorMessage = ref("");
const login = () => {
    signInWithEmailAndPassword(getAuth(), email.value, password.value)
    .then((data)=> {
        router.push('/')
    })
    .catch((e)=>{
        switch (e.code){
            case "auth/invalid-email":
                errorMessage.value = "Correo invalido"
                break;
            case "auth/user-not-found":
                errorMessage.value = "Usuario no encontrado"
                break;
            case "auth/wrong-password":
                errorMessage.value = "Password Incorrecto"
                break;
            case "auth/user-disabled":
                errorMessage.value = "Su cuenta fue desactivada"
                break;
            default:
                errorMessage.value = "Correo o Contraseña invalidos"
                break;
        }
    })
}
</script>

<style scoped>
    p{
        text-align:left;
        margin:5px;
    }
    .login_container{
        background-color: var(--blue-200);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        padding: 30px;
        border-radius: 10px;
    }
    button{
        background-color: var(--blue-900);
        color: white;
        border: 1px solid white;
        padding: 10px 20px;
        border-radius: 10px;
        font-size: large;
    }
</style>